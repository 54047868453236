import { pages } from '@src/constants/pages';

export const actions = {
  contactUs: 'Contact us',
  getQuote: 'Get a quote',
  createAccount: 'Create an account',
  sendMessage: 'Send Message',
  videoPlay: 'Play Video',
  videoPause: 'Pause Video',
  videoEnd: 'End Video',
  downloadBom: 'Download BOM',
  downloadTemplate: 'Download template',
  visitLinkedin: 'Linkedin',
};

const formatCategory = (page, section) => {
  const sectionString = section ? `-${section}` : '';
  return `WS-${page.label}${sectionString}`;
};

export const events = {
  // Home - Banner
  homeBannerStartQuoting: () => ({
    action: actions.getQuote,
    category: formatCategory(pages.home, 'Banner'),
    doc: 'When user clicks on the Start quoting button that leads to wizard.',
  }),
  homeBannerCreateAccount: () => ({
    action: actions.getQuote,
    category: formatCategory(pages.home, 'Banner'),
    doc: 'When user clicks on the Create an account button that leads to wizard and opens Auth0.',
  }),
  homeBannerPlay: () => ({
    action: actions.videoPlay,
    category: formatCategory(pages.home, 'Banner'),
  }),
  homeBannerPause: () => ({
    action: actions.videoPause,
    category: formatCategory(pages.home, 'Banner'),
  }),
  homeBannerEnd: () => ({
    action: actions.videoEnd,
    category: formatCategory(pages.home, 'Banner'),
  }),
  // Home - Challenge
  homeStartQuoting: () => ({
    action: actions.getQuote,
    category: formatCategory(pages.home, 'Challenge'),
    doc: 'When user clicks on the Start quoting button that leads to wizard.',
  }),
  homeContactUs: () => ({
    action: actions.contactUs,
    category: formatCategory(pages.home, 'Challenge'),
    doc:
      'When user clicks on the Contact us button that leads to the contact page.',
  }),

  // About
  aboutStartQuoting: () => ({
    action: actions.getQuote,
    category: formatCategory(pages.about),
    doc: 'When user clicks on the Start quoting button that leads to wizard.',
  }),
  aboutContactUs: () => ({
    action: actions.contactUs,
    category: formatCategory(pages.about),
    doc:
      'When user clicks on the Contact us button that leads to the contact page.',
  }),

  // Contact
  contactMessage: success => ({
    action: actions.sendMessage,
    category: formatCategory(pages.contact),
    label: success ? 'succeeded' : 'failed',
    doc: 'When user send a message through contact form.',
  }),

  // FAQ
  faqDownloadBOM: () => ({
    action: actions.downloadBom,
    category: formatCategory(pages.faq),
    doc: 'When user downloads sample BOM.',
  }),
  faqDownloadTemplate: fileType => ({
    action: actions.downloadTemplate,
    category: formatCategory(pages.faq),
    label: fileType,
    doc: 'When user downloads BOM template.',
  }),

  // Demo
  demoDownloadBOM: () => ({
    action: actions.downloadBom,
    category: formatCategory(pages.demo),
    doc: 'When user downloads sample BOM.',
  }),
  demoDownloadTemplate: fileType => ({
    action: actions.downloadTemplate,
    category: formatCategory(pages.demo),
    label: fileType,
    doc: 'When user downloads BOM template.',
  }),
  demoStartQuoting: () => ({
    action: actions.getQuote,
    category: formatCategory(pages.demo),
    doc: 'When user clicks on the Start quoting button that leads to wizard.',
  }),
  demoCreateAccount: () => ({
    action: actions.getQuote,
    category: formatCategory(pages.demo),
    doc: 'When user clicks on the Create an account button that leads to wizard and opens Auth0.',
  }),
  demoContactUs: () => ({
    action: actions.contactUs,
    category: formatCategory(pages.demo),
    doc:
      'When user clicks on the Contact us button that leads to the contact page.',
  }),

  // Certifications
  certificationsStartQuoting: () => ({
    action: actions.getQuote,
    category: formatCategory(pages.certifications),
    doc: 'When user clicks on the Start quoting button that leads to wizard.',
  }),
  certificationsContactUs: () => ({
    action: actions.contactUs,
    category: formatCategory(pages.certifications),
    doc:
      'When user clicks on the Contact us button that leads to the contact page.',
  }),

  // BOM Help
  bomHelpDownloadTemplate: fileType => ({
    action: actions.downloadTemplate,
    category: formatCategory(pages.bomHelp),
    label: fileType,
    doc: 'When user downloads BOM template.',
  }),

  // Services
  servicesStartQuoting: () => ({
    action: actions.getQuote,
    category: formatCategory(pages.services),
    doc: 'When user clicks on the Start quoting button that leads to wizard.',
  }),
  servicesContactUs: () => ({
    action: actions.contactUs,
    category: formatCategory(pages.services),
    doc:
      'When user clicks on the Contact us button that leads to the contact page.',
  }),

  // Capabilities
  capabilitiesStartQuoting: () => ({
    action: actions.getQuote,
    category: formatCategory(pages.capabilities),
    doc: 'When user clicks on the Start quoting button that leads to wizard.',
  }),
  capabilitiesContactUs: () => ({
    action: actions.contactUs,
    category: formatCategory(pages.capabilities),
    doc:
      'When user clicks on the Contact us button that leads to the contact page.',
  }),

  // Linkedin
  visitLinkedin: () => ({
    action: actions.visitLinkedin,
    category: 'Outbound',
    label: 'footer',
    doc: 'When user navigates to Linkedin.',
  }),

  // Landing Pages
  landingPageEasyReliableFastStartQuoting: () => ({
    action: actions.getQuote,
    category: formatCategory(pages.landingPageEasyReliableFast, 'Banner'),
    doc: 'When user clicks on the Start quoting button on landing page that leads to wizard.',
  }),
  landingPageNoRiskStartQuoting: () => ({
    action: actions.getQuote,
    category: formatCategory(pages.landingPageNoRisk, 'Banner'),
    doc: 'When user clicks on the Start quoting button on landing page that leads to wizard.',
  }),
};

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import urljoin from 'urljoin';

import favicon from '@src/images/icons/favicon.ico';
import { pages } from '@src/constants/pages';
import { devAssert } from '@src/helpers/devAssert';

const SEO = ({ activePageName }) => {
  const activePage = pages[activePageName] || {};
  devAssert(typeof pages[activePageName] !== 'undefined', {
    message: 'Hit non-existing page.',
    activePageName,
  });

  return (
    <Helmet
      title={activePage.title}
      meta={[
        {
          name: 'description',
          content: activePage.description,
        },
        // Open graph
        {
          property: 'og:title',
          content: activePage.title,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: activePage.ogImage,
        },
        {
          property: 'og:url',
          content: urljoin('https://www.adcoproto.com/', activePage.url),
        },
        {
          property: 'og:description',
          content: activePage.description,
        },
        // Twitter
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: 'https://www.salsitasoft.com/',
        },
        {
          name: 'twitter:title',
          content: activePage.title,
        },
        {
          name: 'twitter:description',
          content: activePage.description,
        },
      ]}
      link={[
        {
          rel: 'icon',
          type: 'image/png',
          href: `${favicon}`,
        },
      ]}
    />
  );
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

SEO.propTypes = {
  activePageName: PropTypes.string,
  description: PropTypes.string,
};

export default SEO;

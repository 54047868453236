/* eslint-disable no-console */
import { nodeEnvSwitch } from '@src/helpers/nodeEnvSwitch';

export const getDevAssert = nodeEnv => nodeEnvSwitch({
  production: () => null,
  development: console.assert,
  test: console.assert,
}, nodeEnv);

export const devAssert = getDevAssert(process.env.NODE_ENV);

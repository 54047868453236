import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import _ from 'lodash';
import cx from 'classnames';

import Menu from '@src/components/menu';
import { pages } from '@src/constants/pages';
import * as ConfigValues from '@src/helpers/configValues';

const Header = ({ activePageName }) => (
  <>
    <header className="c-header">
      <ul className="c-header-content">
        <li itemProp="name">
          <a itemProp="url" href={ConfigValues.getAppLoginUrl()}>Log in</a>
        </li>
      </ul>
    </header>
    <div className="c-subheader">
      <div className="c-subheader-content">
        <Link className="c-subheader-logo" to="/" />
        <Menu>
          <ul
            itemScope
            itemType="http://www.schema.org/SiteNavigationElement"
          >
            {_.map(pages, page =>
              page.isInMenu && (
                <li
                  className={cx({ 'active': activePageName === page.name })}
                  key={page.name}
                  itemProp="name"
                >
                  <Link itemProp="url" to={page.path}>{page.label}</Link>
                </li>
              ),
            )}
            <li className="c-subheader-login" itemProp="name">
              <a itemProp="url" href={ConfigValues.getAppLoginUrl()}>Log in</a>
            </li>
          </ul>
        </Menu>
      </div>
    </div>
  </>
);

Header.propTypes = {
  activePageName: PropTypes.string,
};

export default Header;

import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import TermsOfService from '@src/components/termsOfService';
import usFlag from '@src/images/icons/usa-flag.svg';
import upsIcon from '@src/images/icons/ups.svg';
import linkedinLogo from '@src/images/icons/linkedin-logo.svg';

import * as ConfigValues from '@src/helpers/configValues';
import { events } from '@src/constants/gaEvents';
import { customEvent } from '@src/helpers/gtag';

const getYear = () => {
  const today = new Date();
  return today.getFullYear();
};

const Footer = () => {
  const [isTosOpen, setIsTosOpen] = useState(false);
  const openTos = () => setIsTosOpen(true);
  const closeTos = () => setIsTosOpen(false);

  return (
    <footer className="c-footer">
      <section>
        <div>
          <address>
            <ul>
              <li>
                ADCOproto
                <br />
                2868 Bond Street
                <br />
                Rochester Hills, MI 48309, USA
              </li>
            </ul>
            <ul>
              <li>
                Phone:{' '}
                <a href={`tel:${ConfigValues.getAdcoPhone()}`} itemProp="telephone">{ConfigValues.getAdcoPhone()}</a>
              </li>
              <li>Fax: <a href="tel:(248) 853-6698">(248) 853-6698</a></li>
              <li>
                <a
                  className="c-link"
                  href={`mailto:${ConfigValues.getAdcoEmail()}`}
                  itemProp="email"
                >
                  {ConfigValues.getAdcoEmail()}
                </a>
              </li>
            </ul>
          </address>
          <ul className="c-footer-links">
            <li>
              <a
                href="https://www.linkedin.com/company/adco-circuits"
                target="_blank"
                rel="noopener noreferrer"
                onClick={customEvent(events.visitLinkedin())}
              >
                <LazyLoadImage src={linkedinLogo} alt="ADCOproto on Linkedin" />
              </a>
            </li>
          </ul>
        </div>
        <div>
          <ul className="c-footer-benefits">
            <li>
              <LazyLoadImage src={usFlag} alt="US flag" />
              <strong>Assembled in USA</strong>
            </li>
            <li>
              <LazyLoadImage src={upsIcon} alt="UPS" />
              <strong>Free shipping</strong>
            </li>
          </ul>
        </div>
      </section>
      <section className="c-footer-copyright">
        <div>&copy; {getYear()} ADCOproto. All rights reserved.</div>
        <button className="c-link" type="button" onClick={openTos} data-qa-id="tos">
          Terms of service
        </button>
      </section>
      {isTosOpen && <TermsOfService isOpen={isTosOpen} onClose={closeTos} />}
    </footer>
  );
};

export default Footer;

import React from 'react';
import PropTypes from 'prop-types';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
// components
import Modal from '@src/components/modal';
import ModalHeader from '@src/components/modalHeader';

import * as ConfigValues from '@src/helpers/configValues';

const TermsOfService = ({ isOpen, onClose }) => (
  <Modal
    isOpen={isOpen}
    shouldCloseOnOverlayClick={true}
    shouldCloseOnEsc={true}
    onRequestClose={onClose}
  >
    <ModalHeader title="Terms of service" onCloseClick={onClose} />
    <div className="c-modal-content">
      <p id="top">
        Please review our Terms and Conditions, they are an important part of
        our business relationship and govern how we will work together to
        produce the assemblies you are contracting for.
        <br />
        All orders are subject to these Terms and Conditions unless
        specifically agreed to in writing by both parties.
      </p>
      <ul>
        <li><AnchorLink to="#custom_services" title="Custom Services" /></li>
        <li><AnchorLink to="#clarification" title="When we need clarification from you" /></li>
        <li><AnchorLink to="#on_time_delivery" title="On Time Delivery" /></li>
        <li><AnchorLink to="#files_parts" title="Files and parts kit requirements" /></li>
        <li><AnchorLink to="#delivery_guarantee" title="Delivery Guarantee Terms" /></li>
        <li><AnchorLink to="#payment_terms" title="Payment Terms" /></li>
        <li><AnchorLink to="#shipping" title="Shipping" /></li>
        <li><AnchorLink to="#warranty_liability" title="Warranty and Liability" /></li>
      </ul>
      <h3 id="custom_services">Custom Services</h3>
      <p>
        If you require custom services such as wire assembly, box build, hand soldering, or testing,
        we will quote these items separately and give you our delivery timetable at the time of
        quote. The delivery guarantee does not apply to custom services. Email{' '}
        <a href={`mailto:${ConfigValues.getAdcoEmail()}`} itemProp="email">{ConfigValues.getAdcoEmail()}</a>
        {' '}with your request.
      </p>
      <h3 id="clarification">When we need clarification from you</h3>
      <p>
        Quite often there will be some issues that arise in the process of building your assemblies.
        Once the job is on our machines there may be a part that doesn&apos;t fit or a polarity
        marking that is unclear or there is a part shortage.
      </p>
      <ul>
        <li>
          In the event that our process engineers discover an issue during the build that
          would most likely cause you great difficulty such as a BGA that doesn‘t fit the pads
          or incorrect solder dams then we will stop the build and contact you right away to
          discuss options before proceeding with the build. This could add time to our
          delivery guarantee.
        </li>
        <li>
          In the event the problem cannot be resolved immediately due to inability to
          contact you we will either do best industry practices to work around or build
          without.
        </li>
      </ul>
      <h3 id="on_time_delivery">On Time Delivery</h3>
      <p>
        Everything keys off of the date we have all the parts and the files to complete the job.
      </p>
      <ul className="c-list">
        <li>Day one of the build cycle is the first day after receipt of all parts.</li>
        <li>
          Our shipments are in the afternoon so for a 10 day turn job it will be the 10th
          working day after receipt of parts.
        </li>
        <li>
          We will guarantee the ship date either 5, 10, or 15 working days after receipt of all
          parts in proper packaging. We order the parts for a 3 day delivery. For SMT
          components on your BOM, if your MPN calls out non tape and reel (T&amp;R) or tray
          packaging then it may create a delay for us to have them tape and reeled. If there is
          a large quantity of through-hole components and/or hand assembly, then delivery
          may take longer.
        </li>
        <li>Weekends and Holidays are not considered as working days.</li>
      </ul>
      <h3 id="files_parts">Files and parts kit requirements</h3>
      <ul className="c-list">
        <li>
          BOMs must be provided in Microsoft Excel and each line item must contain the
          manufacturer, manufacturers part number, reference designators, and also include
          a Do Not Populate (DNP) list.
        </li>
        <li>
          Assembly drawing and PWB fabrication drawing if available.
        </li>
        <li>
          PWB artwork in Gerber Photoplot files RS-274X
        </li>
        <li>
          For consigned PWB&apos;s we need the working gerbers from the boardhouse. They
          include all resolved engineering issues as well as the finalized panelization/array.
          This is needed to order the stencils.
        </li>
        <li>
          Surface Mount Components to be supplied in Trays or on Tape &amp; Reel. Component
          strips must be in continuous length and have 4 inch leader beyond the quantity
          required. For SMT passive components other than 0201&apos;s you need to include 5%
          extra parts. For 0201 size passives, we need 20% extra. For active components
          (including BGAs) we require 1 extra. No bulk SMT parts or it will it will be
          considered short parts. ADCO will have all tube components tape and reeled for an
          extra charge of $75.00 per part number.
        </li>
        <li>
          Reference designators &amp; polarity signs must be clearly marked on the PWB or
          assembly documentation.
        </li>
        <li>
          All parts with pin 1 or polarity must show some visible mark to identify. If a part is
          not clearly marked with polarity indicators, we require clear assembly drawings.
        </li>
        <li>
          The PWB must be a minimum panel size of 4.25” X 4.25” and have rails or be
          rectangular with 4mm/0.016” of clearance on 2 opposite edges. The maximum
          panel size is 20” x 16”.
        </li>
        <li>
          The PWB must have fiducials.
        </li>
      </ul>
      <h3 id="delivery_guarantee">Delivery Guarantee Terms</h3>
      <ul>
        <li>
          ADCOproto guarantees 100% on time shipment for the labor portion of the job for
          all shipments within the United States except as noted above in On Time Delivery.
        </li>
        <li>
          In the event that we are late with an initial quantity of your order then you will get
          a 10% credit of the labor cost issued to your charge card or account.
        </li>
        <li>
          This guarantee does not apply to situations where the time is extended due to
          clarification issues or to custom services.
        </li>
      </ul>
      <h3 id="payment_terms">Payment Terms</h3>
      <ul className="c-list">
        <li>
          The minimum order is $500.00 in component value and we will charge your card at
          the time your order is placed. We accept Amex, Visa , and Mastercard.
        </li>
        <li>
          We would be happy to offer you Net 30 day terms after we have done at least 5
          orders for you and get adequate financial information to support a credit account.
          This means that we would need a company balance sheet and a credit application.
          Please email{' '}
          <a href={`mailto:${ConfigValues.getAdcoEmail()}`} itemProp="email">{ConfigValues.getAdcoEmail()}</a>
          {' '}or call to discuss.
        </li>
      </ul>
      <h3 id="shipping">Shipping</h3>
      <ul>
        <li>We use UPS as our standard carrier.</li>
        <li>
          If you would like us to charge your shipping account directly please contact
          customer service.
        </li>
        <li>We will ship at no charge UPS ground.</li>
      </ul>
      <h3 id="warranty_liability">Warranty and Liability</h3>
      <ul className="c-list">
        <li>Each assembly is warranted for workmanship for 30 days after shipment.</li>
        <li>
          For any kind of manufacturing workmanship issues caused by ADCOproto please email{' '}
          <a href={`mailto:${ConfigValues.getAdcoEmail()}`} itemProp="email">{ConfigValues.getAdcoEmail()}</a>
          {' '}or call. We reserve
          the right to make the repairs or refund the applicable amount of assembly labor to settle
          the issue. Please do not attempt the repair yourself because this will nullify the
          warranty.
        </li>
        <li>
          ADCOproto is not responsible for any damage or lack of functionality caused by
          defective design, components or PCBs provided by you or as a result of deficiencies
          in the electronic materials provided by you.
        </li>
        <li>
          In no event shall ADCOprotos&apos; liability exceed the amount of the order placed.
        </li>
        <li>
          ADCOproto gives no other warranty; either expressed or implied, and specifically
          disclaims all other warranties, including warranties for merchantability and fitness.
        </li>
      </ul>
    </div>
    <div className="c-modal-scroll-to-top"><AnchorLink to="#top" title="Scroll to top" /></div>
  </Modal>
);

TermsOfService.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TermsOfService;

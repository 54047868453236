/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import Header from '@src/components/header';
import Footer from '@src/components/footer';
import SEO from '@src/components/seo';
import '@src/styles/main.scss';

const Layout = ({ children, className, pageName, schema, title }) => (
  <div className="c-app" itemScope itemType={schema}>
    <Header activePageName={pageName} />
    <SEO activePageName={pageName} title={title} />
    <main className={className}>{children}</main>
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  pageName: PropTypes.string.isRequired,
  schema: PropTypes.string,
  title: PropTypes.string,
};

export default Layout;

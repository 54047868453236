import logdown from 'logdown';

const logger = logdown('aw-gtag');

const developmentCustomEvent = ({ action, category, label, value }) => () =>
  logger.debug('[development] Google Analytics Event: ', {
    action,
    category,
    label,
    value,
  });

const productionCustomEvent = ({ action, category, label, value }) => () => {
  const gtag = window.gtag;
  if (typeof gtag !== 'function') {
    // fail silently in production mode
    logger.error(' window.gtag not defined.');
  }
  // Reference:
  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};

export const customEvent = eventProps => {
  if (typeof window === 'undefined') {
    // Server Side Rendered (on build)
    return null;
  }
  if (process.env.NODE_ENV === 'development') {
    return developmentCustomEvent(eventProps);
  }
  return productionCustomEvent(eventProps);
};

import { formatTitle } from '@src/helpers/valueHelpers';

import * as ConfigValues from '@src/helpers/configValues';

const ogImageAbout = 'https://adco-s3-dev-web.s3.amazonaws.com/og/about.jpg';
const ogImageHome = 'https://adco-s3-dev-web.s3.amazonaws.com/og/home.jpg';
const ogImageDemo = 'https://adco-s3-dev-web.s3.amazonaws.com/og/demo.jpg';

export const pages = {
  home: {
    isInMenu: true,
    name: 'home',
    path: '/',
    label: 'Home',
    ogImage: ogImageHome,
    title: formatTitle('Prototypes and low volume PCB assembly services'),
    description: 'Get an instant online printed circuit board assembly production quote for any custom needs you may have with ADCOproto\'s secure online quoting system.',
    schema: '',
  },
  about: {
    isInMenu: true,
    name: 'about',
    path: '/about',
    label: 'About us',
    ogImage: ogImageAbout,
    title: formatTitle('About us'),
    description: 'ADCOproto brings 36 years of complex electronic assembly experience to solve even the most challenging prototypes.',
    schema: 'https://schema.org/AboutPage',
  },
  demo: {
    isInMenu: true,
    name: 'demo',
    path: '/demo',
    label: 'How it works',
    ogImage: ogImageDemo,
    title: formatTitle('How it works'),
    description: 'Challenge us with your toughest PCB assembly prototype. Use our interactive online BOM tool to locate stock and specify parts across multiple distributors and manufacturers.',
    schema: '',
  },
  faq: {
    isInMenu: true,
    name: 'faq',
    path: '/faq',
    label: 'FAQ',
    ogImage: ogImageHome,
    title: formatTitle('Frequently Asked Questions'),
    description: 'We have compiled the most frequently asked questions from our customers to help you plan and complete your quote fast and easy.',
    schema: 'https://schema.org/FAQPage',
  },
  contact: {
    isInMenu: true,
    name: 'contact',
    path: '/contact',
    label: 'Contact',
    ogImage: ogImageHome,
    title: formatTitle('Contact'),
    description: `Do you have question or a specific request? Call us directly at ${ConfigValues.getAdcoPhone()} or send us a message.`,
    schema: 'https://schema.org/ContactPage',
  },
  certifications: {
    isInMenu: false,
    name: 'certifications',
    path: '/certifications',
    label: 'Certifications',
    ogImage: ogImageHome,
    title: formatTitle('Certifications'),
    description: 'We are ISO 9001, AS9100, and IATF 16949 registered. Our workforce is trained for Class II and Class III standards by an IPC certified trainer to J-STD-001 and IPC 610.',
    schema: '',
  },
  services: {
    isInMenu: false,
    name: 'services',
    path: '/services',
    label: 'Services',
    ogImage: ogImageHome,
    title: 'Services — ADCOproto',
    description: 'PCB assembly services.',
    schema: '',
  },
  capabilities: {
    isInMenu: false,
    name: 'capabilities',
    path: '/capabilities',
    label: 'Capabilities',
    ogImage: ogImageHome,
    title: 'Capabilities — ADCOproto',
    description: 'PCB assembly capabilities.',
    schema: '',
  },
  bomHelp: {
    isInMenu: false,
    name: 'bomHelp',
    path: '/bomHelp',
    label: 'BOM Help',
    ogImage: ogImageHome,
    title: 'BOM Help — ADCOproto',
    description: 'Help for your BOM.',
    schema: '',
  },
  'notFound': {
    isInMenu: false,
    name: 'notFound',
    path: '/notFound.htm',
    label: 'Not Found',
    ogImage: null,
    title: formatTitle('Not found'),
    description: 'Page which you requested cannot be found.',
    schema: '',
  },
  landingPageEasyReliableFast: {
    isInMenu: false,
    name: 'pcb-prototypes-easy-reliable-fast',
    path: 'pcb-prototypes-easy-reliable-fast',
    label: 'PCB Prototypes - Easy, Reliable, Fast',
    ogImage: ogImageHome,
    title: 'PCB Prototypes Easy, Reliable, Fast — ADCOproto',
    description: 'PCB Prototypes Easy, Reliable, Fast',
    schema: '',
  },
  landingPageNoRisk: {
    isInMenu: false,
    name: 'pcb-prototypes-no-risk-guaranteed',
    path: 'pcb-prototypes-no-risk-guaranteed',
    label: 'PCB Prototypes - No Risk Guaranteed',
    ogImage: ogImageHome,
    title: 'PCB Prototypes No Risk Guaranteed — ADCOproto',
    description: 'PCB Prototypes No Risk Guaranteed',
    schema: '',
  },
};

import React from 'react';
import PropTypes from 'prop-types';

const ModalHeader = ({ title, onCloseClick }) => (
  <header>
    <h3>{title}</h3>
    <div className="c-modal-close" onClick={onCloseClick} />
  </header>
);

ModalHeader.propTypes = {
  title: PropTypes.string,
  onCloseClick: PropTypes.func,
};

export default ModalHeader;

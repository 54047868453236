import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

class Modal extends React.PureComponent {
  componentDidMount() {
    ReactModal.setAppElement(document.getElementById('root'));
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      contentLabel,
      children,
      ...restProps
    } = this.props;
    const modalClassNames = ['c-modal'];
    if (restProps.className) {
      modalClassNames.push(restProps.className);
    }
    return (
      <ReactModal
        {...{ isOpen, onRequestClose, contentLabel }}
        {...restProps}
        overlayClassName="c-modal-overlay"
        className={modalClassNames.join(' ')}
      >
        {children}
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  contentLabel: PropTypes.string,
  children: PropTypes.any,
};

export default Modal;

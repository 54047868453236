import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

class Menu extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      isOpen: false,
    };
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  toggleContent = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { children } = this.props;
    const { isOpen } = this.state;

    return (
      <div
        className={cx('c-menu', {
          'is-open': isOpen,
        })}
      >
        <button
          className="c-menu-toggle"
          onClick={this.toggleContent}
          type="button"
        >
          <span />
        </button>
        {children}
      </div>
    );
  }
}

export default Menu;
